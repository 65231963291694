"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserGroup = void 0;
var UserGroup;
(function (UserGroup) {
    UserGroup["DEALER"] = "dealer";
    UserGroup["DEALER_ADMIN"] = "dealerAdmin";
    UserGroup["DIRECTV_ADMIN"] = "directvAdmin";
    UserGroup["DERECTV_MARKETING"] = "directvMarketing";
    UserGroup["EVENT_VENDOR"] = "eventVendor";
    UserGroup["EVENT_VENDOR_ADMIN"] = "eventVendorAdmin";
    UserGroup["PRODUCT_VENDOR"] = "productVendor";
    UserGroup["PRODUCT_VENDOR_ADMIN"] = "productVendorAdmin";
    UserGroup["SUPER_ADMIN"] = "superAdmin";
    UserGroup["VENDOR"] = "vendor";
    UserGroup["VENDOR_ADMIN"] = "vendorAdmin";
    UserGroup["ZONER"] = "zoner";
})(UserGroup = exports.UserGroup || (exports.UserGroup = {}));
